<template>
  <div ref="resizeRef">
    <svg ref="svgRef">
      <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
      <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
    </svg>
  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { select, scaleBand, scaleLinear, axisTop, axisLeft, max, min } from 'd3'
import { tip } from 'd3-v6-tip'
import { format } from 'd3-format'

import useResizeObserver from '@/tools/resizeObserver'
import { days, times } from '@/constants'

export default {
  name: 'HeatMapChart',
  props: ['data', 'axis', 'showBy'],
  setup(props) {
    const { resizeRef, resizeState } = useResizeObserver()
    const svgRef = ref(null)
    const chartData = ref(props.data)

    const formatValue = format(',.0f')
    const formatCurrency = format(',.2f')
    const colors = ['#e4ff54', '#ff6d00']

    const listTimes = [
      ...new Set(
        chartData.value
          .sort((a, b) => (a.time < b.time ? -1 : 1))
          .map(item => item.time)
      )
    ]

    const minTimes = computed(() => Math.min(...listTimes))
    const maxTimes = computed(() => Math.max(...listTimes))

    const filteredTimes = computed(() => {
      return times
        .filter(
          item => item.value >= minTimes.value && item.value <= maxTimes.value
        )
        .map(item => item.name)
    })

    const rowData = computed(() => {
      switch (props.showBy) {
        case 'trx_count': {
          return 'trx_count'
        }
        default: {
          return 'sales'
        }
      }
    })

    const rowName = computed(() => {
      switch (props.showBy) {
        case 'trx_count': {
          return 'Transaction Count'
        }
        default: {
          return 'Net Sales'
        }
      }
    })

    onMounted(() => {
      watchEffect(() => {
        let { width, height } = resizeState.dimensions

        const margin = { top: 20, right: 20, bottom: 80, left: 20 }
        width = width - margin.left - margin.right
        height = (width - margin.top - margin.bottom) / 3

        const svg = select(svgRef.value)
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        const xScale = scaleBand()
          .domain(filteredTimes.value)
          .rangeRound([0, width])
          .padding(0.05)

        const yScale = scaleBand()
          .domain(days)
          .rangeRound([0, height])
          .padding(0.05)

        const xAxis = axisTop(xScale).tickSize(0)
        const yAxis = axisLeft(yScale).tickSize(0)

        // Build color scale
        const heatColor = scaleLinear()
          .range(colors)
          .domain([
            min(chartData.value, v => v[rowData.value]),
            max(chartData.value, v => v[rowData.value])
          ])

        // Refresh Graph
        svg.selectAll('rect').remove()
        svg.selectAll('.x-label').remove()

        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((event, data) => {
            const tooltipValue =
              props.showBy === 'trx_count'
                ? formatValue(data[rowData.value])
                : formatCurrency(data[rowData.value])

            return (
              'Day: ' +
              data.day +
              '<br>' +
              'Time: ' +
              data.timeName +
              '<br>' +
              rowName.value +
              ': ' +
              tooltipValue
            )
          })

        svg.call(tooltip)

        const heatChart = svg
          .append('g')
          .selectAll('.heatbar')
          .data(chartData.value, d => d.day + ':' + d.timeName)
          .join('rect')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('x', d => xScale(d.timeName))
          .attr('y', d => yScale(d.day))
          .attr('width', xScale.bandwidth())
          .attr('height', yScale.bandwidth())
          .style('fill', colors[0])
          .on('mouseover', tooltip.show)
          .on('mouseout', tooltip.hide)

        // Animate graph
        heatChart
          .transition()
          .duration(1000)
          .style('fill', d => heatColor(d[rowData.value]))

        svg
          .select('.x-axis')
          .call(xAxis)
          .attr('class', 'x-axis')
          .select('.domain')
          .remove()
        svg
          .select('.y-axis')
          .call(yAxis)
          .attr('class', 'y-axis')
          .select('.domain')
          .remove()
      })
    })

    return { svgRef, resizeRef }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .bar {
    fill: $blue-8;

    &.primary {
      fill: $blue-8;
    }

    &.warning {
      fill: $orange-8;
    }

    &.success {
      fill: $green-8;
    }

    &.info {
      fill: $blue-4;
    }
  }

  .y-axis,
  .x-axis {
    font-weight: 600;
    line,
    path {
      color: $grey-6;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}
</style>
