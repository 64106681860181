<template>
  <div ref="resizeRef" class="pt-8">
    <svg ref="svgRef" :id="id">
      <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
      <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
    </svg>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import {
  select,
  scaleBand,
  scaleLinear,
  axisBottom,
  axisLeft,
  max,
  easeCubic
} from 'd3'
import { tip } from 'd3-v6-tip'
import { format } from 'd3-format'
import useResizeObserver from '@/tools/resizeObserver'

export default {
  name: 'BarChart',
  props: ['id', 'data', 'type', 'axis', 'xLabel', 'yLabel', 'xData', 'yData'],
  setup(props) {
    const svgRef = ref(null)
    const { resizeRef, resizeState } = useResizeObserver()

    onMounted(() => {
      watchEffect(() => {
        let { width, height } = resizeState.dimensions
        let maxw = 0
        let maxh = 0
        const margin = { top: 20, right: 20, bottom: 80, left: 20 }

        width = width - margin.left - margin.right
        height = 350 - margin.top - margin.bottom

        const svg = select(svgRef.value)
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        svg
          .select('.y-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxw) maxw = this.getBBox().width
          })

        svg
          .select('.x-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().height > maxh) maxh = this.getBBox().height
          })

        const xScale = scaleBand()
          .domain(props.data.map(v => v[props.xData]))
          .rangeRound([0, width - maxw])
          .padding(0.1)

        const yScale = scaleLinear()
          .domain([
            0,
            max(props.data, v => {
              return Math.max(v[props.yData])
            })
          ])
          .rangeRound([height, 0])

        const xAxis = axisBottom(xScale)
        const yAxis = axisLeft(yScale)

        const formatValue = format(',.2f')

        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v) => {
            const amount = formatValue(v[props.yData])
            if (props.type === 'DailySales') {
              return (
                'Day: ' + v[props.xData] + '<br>' + 'Net Sales' + ': ' + amount
              )
            } else if (props.type === 'TopBranches') {
              return (
                'Store: ' +
                v[props.xData] +
                '<br>' +
                'Transactions' +
                ': ' +
                amount
              )
            } else {
              return amount
            }
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('rect').remove()
        svg.selectAll('.x-label').remove()
        svg.selectAll('.y-label').remove()

        const barGraph = svg
          .append('g')
          .selectAll('.bar')
          .data(props.data)
          .enter()
          .append('rect')
          .classed('bar', true)
          .attr('fill', '#2e4faa')
          .attr('width', xScale.bandwidth())
          .attr('height', () => height - yScale(0))
          .attr('x', v => xScale(v[props.xData]))
          .attr('y', () => yScale(0))
          .on('mouseover', tooltip.show)
          .on('mouseout', tooltip.hide)

        // set animation
        barGraph
          .transition()
          .ease(easeCubic)
          .duration(800)
          .attr('y', v => {
            return yScale(v[props.yData])
          })
          .attr('height', v => {
            return height - yScale(v[props.yData])
          })
          .delay((v, i) => i * 5)

        svg
          .select('.x-axis')
          .attr('class', 'x-axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis)
          .selectAll('text')
          .style('text-anchor', 'start')
          .attr('dx', '10px')
          .attr('dy', '-2px')
          .attr('transform', 'rotate(65)')

        svg
          .select('.x-axis')
          .append('text')
          .attr('class', 'x-label')
          .attr('text-anchor', 'end')
          .attr('x', (width - margin.left) / 2)
          .attr('y', maxh + margin.bottom)
          .text(props.xLabel)

        svg
          .select('.y-axis')
          .call(yAxis)
          .attr('class', 'y-axis')
          .append('text')
          .attr('class', 'y-label')
          .attr('transform', 'rotate(-90)')
          .attr('x', -(height - margin.top) / 2)
          .attr('y', -maxw - 10)
          .attr('dy', -16)
          .style('text-anchor', 'end')
          .text(props.yLabel)

        svg
          .attr('transform', 'translate(' + (maxw + margin.left) + ',0)')
          .attr('height', height + margin.top + margin.bottom + maxh)
      })
    })

    return { svgRef, resizeRef }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .bar {
    fill: $blue-8;

    &.primary {
      fill: $blue-8;
    }

    &.warning {
      fill: $orange-8;
    }

    &.success {
      fill: $green-8;
    }

    &.info {
      fill: $blue-4;
    }
  }

  .y-axis,
  .x-axis {
    line,
    path {
      color: $grey-6;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}
</style>
